<template>
    <div class="distributionDetail">
        <el-breadcrumb separator-class="el-icon-arrow-right">
  			<el-breadcrumb-item :to="{ path: '/home/index' }">概况总览</el-breadcrumb-item>
  			<el-breadcrumb-item >收入详情</el-breadcrumb-item>
		</el-breadcrumb>
        <div class="incomeDetaial">
            <div class="topType">
				<span class="text">收入详情</span>
			</div>
            <div class="incomeList">
                <el-table :data="orderData">
					<el-table-column prop="reward" label="收益" align="center"></el-table-column>
					<el-table-column prop="createdAt" label="时间" align="center"></el-table-column>
					<el-table-column label="类型" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.rewardType == 30">每PPS成本</span>
						    <span v-if="scope.row.rewardType == 31">剩余60%</span>
						    <span v-if="scope.row.rewardType == 20">固定20%</span>
						    <span v-if="scope.row.rewardType == 21">剩余40%</span>
						    <span v-if="scope.row.rewardType == 5">间推5%</span>
						    <span v-if="scope.row.rewardType == 10">直推10%</span>
						</template>
					</el-table-column>
					<el-table-column prop="id" label="订单编号" align="center"></el-table-column>
      				<el-table-column prop="userId" label="用户编号" align="center"></el-table-column>
                    <el-table-column prop="ppsAmount" label="购买价格" align="center"></el-table-column>
                    <el-table-column prop="ppsOrder" label="购买数量" align="center"></el-table-column>
                    <el-table-column prop="paymentAt" label="购买时间" align="center"></el-table-column>
    			</el-table>
            </div>
        </div>
        <div class="allocationDetaial">
            <div class="topType">
				<span class="text">分配详情</span>
			</div>
            <div class="allocationList">
                <el-table :data="allocationData">
					<el-table-column prop="orderId" label="订单编号" align="center"></el-table-column>
      				<el-table-column prop="reward" label="收益" align="center"></el-table-column>
                    <el-table-column prop="" label="收益类型" align="center">
                        <template slot-scope="scope">
							<span v-if="scope.row.rewardType == 30">每PPS成本</span>
                            <span v-if="scope.row.rewardType == 31">剩余60%</span>
                            <span v-if="scope.row.rewardType == 20">固定20%</span>
                            <span v-if="scope.row.rewardType == 21">剩余40%</span>
                            <span v-if="scope.row.rewardType == 5">间推5%</span>
                            <span v-if="scope.row.rewardType == 10">直推10%</span>
						</template>
                    </el-table-column>
    			</el-table>
            </div>
        </div>
    </div>
</template>

<script>
import {
		Distribution
	} from "@/api/index";
export default {
    data() {
        return {
            id: '',
			orderId: '',
			tableData: {},
            orderData:[],
			allocationData:[],
			type: '',
			//刷新
			loading: false,
			page: {
			  current_page: 1,
			  pagesize: 10
			 },
        }
    },
    created() {
        let {
			id,orderId
		} = this.$route.query;
		if (this.id == '') {
			this.id = id
		}
		if (this.orderId == '') {
			this.orderId = orderId
		}
		this.Detail();
		this.orderDetail();
    },
    methods:{
        async Detail() {
				let {
					status,
					data
				} = await Distribution.incomedetail({
					id: this.id
				});
				if (status) {
					if (data.code == 0) {
						this.tableData = data.data.detail;
						let NewObj = {
							reward:data.data.detail.reward,
							rewardType:data.data.detail.rewardType,
							createdAt:data.data.detail.createdAt
						}
						 this.orderData.push({...NewObj,...data.data.detail.Order})
					}
				}
			},
			async orderDetail() {
				if(this.$store.state.base.userId == 10 || this.$store.state.base.userId == 1) {
						let {
							status,
							data
						} = await Distribution.orderdetail({
							orderId: this.orderId,
						});
						if (status) {
							if (data.code == 0) {
								if (data.data.list == null) {
								} else {
									this.allocationData = data.data.list;
								}
							}
						}
					
				}
			},
    },
}
</script>

<style lang="less">
    .distributionDetail {
        body {
			margin: 0;
			padding: 0;
			background-color: #F0F3FA;
		}
        .el-breadcrumb{
			padding: 30px;
			.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link {
				color: #999999;
				font-weight: 500;
			}
			.el-breadcrumb__item:last-child .el-breadcrumb__inner {
				color: #1493FA;
			}
		}
        .incomeDetaial {
            background-color: #fff;
			margin: 20px;
            border-radius: 10px;
            .topType {
				background-color: #F8F8F8;
				padding: 9px 20px;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
				.text {
					font-size: 18px;
					color: #333333;
					font-weight: 600;
					margin-left: 10px;
				}
			}
			.topType::before{
				content:"";
				display: inline-block;
				width: 3px;
				height: 16px;
				background: #1493FA;
				vertical-align: middle;
			}
            .incomeList {
                padding: 10px;
            }
        }
        .allocationDetaial {
            background-color: #fff;
			margin: 20px;
            border-radius: 10px;
            .topType {
				background-color: #F8F8F8;
				padding: 9px 20px;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
				.text {
					font-size: 18px;
					color: #333333;
					font-weight: 600;
					margin-left: 10px;
				}
			}
			.topType::before{
				content:"";
				display: inline-block;
				width: 3px;
				height: 16px;
				background: #1493FA;
				vertical-align: middle;
			}
            .allocationList {
                padding: 10px;
            }
        }
    }
</style>